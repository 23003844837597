.stream {
  z-index: 14;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--stream-bg);

  &::after {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
    top: 70px;
    right: 20px;
    width: 39px;
    height: 39px;
    background-color: var(--basic-color);
    background-image: url('https://m.lvbet.pl/img/new-version/icons/black/swipe-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border-radius: 50%;
    content: '';
  }

  .no-match-to-swipe-info{
    z-index: 1;
    position: absolute;
    bottom: 0;
    transform: translateY(50px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 150px;
    height: 45px;
    padding: 10px 0;
    background: var(--btn-bg);
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: var(--ls-1);
    transition: .3s;
  }

  &.no-match-to-swipe{
    .no-match-to-swipe-info{
      transform: translateY(0px);
    }
  }


  &.is-scroll::after {
    animation: pulse-swap 10s linear 0.5s forwards;
  }

  &-close {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: calc(20px + var(--safe-area-right));
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @media screen and (max-height: 414px) {
      top: 5px;
      right: calc(10px + var(--safe-area-right));
    }

    @media screen and (max-height: 380px) {
      right: calc(5px + var(--safe-area-right));
    }

    .icon-logo {
      opacity: 0.6;
      display: block;
      width: 100px;
      height: 20px;
      margin-right: 15px;
      background-position: 100%;
    }

    .icon-times {
      width: 39px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--btn-bg);
      border-radius: var(--radius-primary);
      svg {
        width: 15px;
      }
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    height: 35px;
    background: var(--btn-bg);
    color: var(--white);
    font-size: 14;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: var(--ls-1);
    border-radius: var(--radius-primary);

    &-show {
      margin: 0 auto 50px auto;
    }

    &-hide {
      margin: 0 0 15px auto;
      margin-right: calc(0px + var(--safe-area-right));
    }

    &-back {
      position: absolute;
      top: 0;
      right: -20px;
      transform: translateX(100%);

      @include breakpoint-max(650px) {
        top: initial;
        bottom: 0;
      }
    }
  }

  &-video {
    display: block;
    width: 100%;
    height: 100%;

    video {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    z-index: 1;
    position: absolute;
    padding-top: 20px;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &-markets-list {
    overflow: auto;
    display: flex;
    grid-gap: 15px;
    padding-bottom: calc(12px + var(--safe-area-bottom));
  }

  .markets {
    display: flex;
    &-entry {
      margin-top: auto;
    }
  }

  .markets-group-cp {
    &:first-child {
      margin-left: 15px;
    }

    &:last-child {
      margin-right: 15px;
    }
  }

  .markets-list-cp {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    margin: 0;
  }

  .odds {
    background: var(--odds-bg);

    &__name {
      margin-right: 20px;
      white-space: nowrap;
    }
  }

  &-markets {
    &__entry {
      z-index: 1;
      position: relative;
      display: flex;
      flex-flow: column;
      align-self: flex-start;
      padding: 10px;
      margin-left: 15px;
      background: var(--market-bg);
      border-radius: var(--radius-primary);
    }

    &__odds {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 10px;
    }

    .markets-entry {
      z-index: 1;
      position: relative;
      display: flex;
      flex-flow: column;
      min-height: 92px;
      align-self: flex-start;
      margin-bottom: 0;
      padding: 10px 10px 2px 10px;
      margin-left: 12px;
      background: var(--market-bg);
      border-radius: var(--radius-1);
      backdrop-filter: blur(10px);

      .icon-arrow-down {
        display: none;
      }

      &.has-range {
        padding-bottom: 0;
        background: var(--market-bg-range);

        .markets-entry__odds {
          display: flex;
          flex-direction: column;
        }

        .slider-range {
          display: inline-block;
          width: 100%;
          margin: -10px 0 5px;
          padding: 0;
          transition: opacity 0.2s ease;

          &__value {
            font-size: 10px;
            &--current {
              background: transparent;
              &::after {
                width: 16px;
                height: 16px;
              }
            }

            &--end {
              transform: translateX(23px);
            }
          }
        }
      }

      &__headline {
        margin-bottom: 12px;
        font-size: 11px;
      }

      &__odds {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
        grid-auto-flow: column;

        div {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 8px;
          grid-auto-flow: column;
          .markets-entry__all {
            display: none;
          }
        }

        .odds {
          height: 35px;
          padding: 0 14px;
          font-size: 11px;
          &__name {
            color: var(--odds-color);
          }
          &__value {
            color: var(--white);
          }
        }
      }

      &__title {
        white-space: nowrap;
      }
    }
  }

  .betslip-keyboard {
    position: absolute;
    top: 20px;
    left: calc(20px + var(--safe-area-left));
    width: 290px;
    padding: 10px 10px 5px;
    background: var(--keyboard-bg);
    border-radius: var(--radius-primary);
    backdrop-filter: blur(10px);

    .btn {
      height: 36px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px;
      color: var(--white);
      font-size: 13px;
      font-weight: 700;
      letter-spacing: -0.05em;
      border-radius: var(--radius-primary);

      @media screen and (max-height: 414px) {
        height: 34px;
      }

      @media screen and (max-height: 380px) {
        height: 32px;
      }

      &-stake {
        margin-right: 8px;
        background-color: var(--satke-bg);
        font-size: 12px;
        font-weight: 600;

        @media screen and (max-height: 414px) {
          margin-right: 5px;
        }

        &:last-child {
          margin: 0;
        }
      }

      &--bg-primary {
        background-color: var(--body);
        color: var(--white);
      }

      &--bg-transparent {
        background-color: transparent;
        border: 2px solid #696969;
      }
    }

    &__close {
      position: absolute;
      min-width: 150px;
      top: 0;
      right: -20px;
      transform: translateX(100%);
      display: flex;
      background: var(--close-bg);
      font-size: 14px;
      text-transform: capitalize;
      border: none !important;

      @media screen and (max-width: 650px) {
        top: initial;
        bottom: 0;
      }
    }

    &__header {
      margin-bottom: 10px;
    }

    &__info {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__title {
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      margin-right: 10px;
      font-size: 14px;
      text-transform: capitalize;
      white-space: nowrap;

      @media screen and (max-height: 380px) {
        font-size: 13px;
        line-height: 1;
      }

      span {
        overflow: hidden;
        width: 100%;
        margin-left: 5px;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__subtitle {
      flex-shrink: 0;
      margin-right: 5px;
      color: var(--f-grey);

      &::after {
        display: none;
      }

      &.tax-info {
        display: none;
      }
    }

    &__markets {
      overflow: hidden;
      display: grid;
      grid-gap: 10px;
      margin-bottom: 10px;

      @media screen and (max-height: 360px) {
        grid-gap: 5px;
      }

      &__title {
        overflow: hidden;
        display: block;
        color: var(--f-grey);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.06em;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-height: 380px) {
          font-size: 11px;
        }
      }

      &__odds {
        overflow: hidden;
        position: relative;
        padding: 11px 60px 11px 14px;
        background-color: var(--odds-bg);
        color: var(--black);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.05em;
        line-height: 1;
        border-radius: var(--radius-primary);
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-height: 414px) {
          padding: 10px 60px 10px 14px;
        }

        @media screen and (max-height: 380px) {
          padding: 9px 60px 9px 14px;
        }

        span {
          position: absolute;
          top: 50%;
          right: 14px;
          transform: translateY(-50%);
        }
      }
    }

    &__form {
      margin: 0;
    }

    &__place {
      align-items: flex-end;
      margin-bottom: 10px;

      @media screen and (max-height: 360px) {
        margin-bottom: 5px;
      }

      .btn {
        flex-shrink: 0;
        min-width: 0;
        padding: 0 10px;
        font-size: 14px;
        white-space: nowrap;

        &.is-disabled{  
          pointer-events: none;
          opacity: .3;
        }

        &--bg-basic {
          color: var(--black);
          background-color: var(--basic-color);
        }

        &-confirm,
        &-cancel {
          font-size: 13px;
          color: var(--white);
        }

        &-confirm {
          background-color: var(--primary-green);
        }

        &-cancel {
          background-color: var(--primary-red);
        }
      }
    }

    &__stake {
      margin-bottom: 0;
      @media screen and (max-height: 360px) {
      }
    }

    &__input-box {
      display: flex;
      flex-flow: column;
      margin-right: 8px;

      @media screen and (max-height: 414px) {
        margin-right: 5px;
      }

      .betslip-keyboard__input {
        height: 36px;
        min-width: 0;
        flex-shrink: 0;
        font-weight: 700;

        @media screen and (max-height: 414px) {
          height: 34px;
        }

        @media screen and (max-height: 380px) {
          height: 32px;
        }
      }

      betslip-virtual-keyboard-selection-errors {
        height: 17px;
        order: -1;
        transition: height 0.15s ease;

        &:empty {
          height: 0;
        }
      }

      .betslip-keyboard-error {
        opacity: 1;
        top: -4px;
        padding: 3px 7px;
        background: var(--error-bg);
        color: var(--white);
        border-radius: 10px;
        transform: initial;
        text-align: center;
        animation: opacity-default-animation 0.1s linear 0.15s forwards;

        &~ .betslip-keyboard__input{margin-top: 20px;}
      }

    }

    &__core {
      grid-row-gap: 5px;
      .btn {
        flex: 0 1 calc((100% - 16px) / 3);

        @media screen and (max-height: 360px) {
          margin-bottom: 3px;
        }
      }

      @media screen and (max-height: 414px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 5px;
      }
    }

    &__btns {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      grid-auto-flow: column;
      grid-gap: 8px;
      flex-shrink: 0;

      &.has-choice {
        min-width: calc(66.66% - 4px);
      }
    }

    &__input {
      height: 100%;
    }

    &.status-active {
      width: 100%;
      height: 100%;
      left: 0;
      background: transparent;
      backdrop-filter: none;

      .betslip-keyboard__markets {
        grid-gap: 5px;
      }

      .betslip-keyboard__status {
        width: 100%;
        height: fit-content;
        position: absolute;
        left: 0;
        bottom: 20px;
        padding: 10px;
        background-color: var(--status-bg);

        .betslip-keyboard__markets__odds {
          padding: 0;
          background: transparent;
          color: var(--white);
          span {
            display: none;
          }
        }

        .betslip-keyboard__info {
          flex-direction: row-reverse;
          justify-content: end;
          text-transform: uppercase;

          .betslip-keyboard__title {
            color: var(--info-color);
            font-size: 10px;
            font-weight: 600;
            letter-spacing: -0.025em;
            text-transform: uppercase;

            span {
              color: var(--info-color);
            }
          }
        }

        .betslip-keyboard__subtitle {
          margin-right: 10px;
          color: var(--info-color);
        }
      }

      .fast-bet-status {
        position: relative;
        top: 45%;
        margin-left: auto;
        text-align: right;
        transform: translate(0); 
        justify-content: end;
      }

      .is-processing {
        color: var(--basic-color);
      }

      .is-placed {
        color: var(--placed-color);
      }

      .is-canceled {
        color: var(--canceled-color);
      }

      .is-canceled-message {
        font-size: 10px;
        &~ .btn{margin-left: 12px;}
      }
    }
  }

  .no-content__description {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-size: 14px;
  }

}
