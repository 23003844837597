@import 'sportsbook/mobile-web/shared/components/watch-and-bet';

.stream {
  --stream-bg: var(--body-bg);
  --btn-bg: rgba(49, 49, 55, 0.8);

  .betslip-keyboard {
    --keyboard-bg: rgba(27, 27, 31, 0.6);
    --stake-bg: #696969;
    --odds-bg: var(--basic-color);
    --error-bg: #ad0000;
    --info-color: #7a7a7f;
    --close-bg: rgba(49, 49, 55, 0.8);
    --status-bg: rgba(0, 0, 0, 0.75);
    --placed-color: #00b372;
    --canceled-color: #ff1100;
  }

  .markets-entry {
    --market-bg: rgba(27, 27, 31, 0.6);
    --market-bg-range: rgba(27, 27, 31, 0.85);
    --market-title-color: #b0b0ba;
  }

  .odds {
    --odds-bg: #313137;
    --odds-color: #b0b0ba;
  }
}
