@import 'shared/common/lvbet/payments/com-lv/payments-icons';

.modal--deposit-blik-processing {
  &.modal {
    background-color: var(--body-bg);
  }
  .modal {
    &-content {
      &__text {
        font-size: 18px;
        font-weight: 700;
        color: var(--white);
        text-align: center;
      }

      &__deposit-operator {
        position: relative;
        max-width: 160px;
        height: 100px;
        margin: 0 auto 20px auto;
        border-radius: var(--radius-s);
        background: var(--method-logo-path, var(--modal-bg)) no-repeat center / cover;

        &--proccessing {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--basic-color) var(--icon-letter-i-black) center / 18px no-repeat;
          transform: translate(50%, 35%);
        }
      }
    }
  }
}
