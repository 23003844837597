:root {
    //Icons path
    --icon-logo: url("https://lvbet-static.com/images/bcf/mobile/img/logo.svg");
    --icon-payments: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/payments.svg");
    --icon-payments-loagin-loader: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/grey/payments-pending.svg");
    --icon-close-white: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/close-thick.svg");
    --icon-tick-basic: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/basic/tick.svg");
    --icon-tick-black: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/black/tick.svg");
    --icon-tick-white: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/tick.svg");
    --icon-failure-black: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/black/failure.svg");
    --icon-letter-i-black: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/black/letter-i.svg");
    --icon-arrow-down-2: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/grey/arrow-down-2.svg");
    --icon-arrow-down-3: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/grey/arrow-down-3.svg");
    --icon-chevron-left: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/chevron-left.svg");
    --icon-chevron-left-active: url("https://lvbet-static.com/images/bcf/mobile/img/icons/yellow/chevron-left.svg");
    --icon-chevron-down: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/chevron-down.svg");
    --icon-arrow-back: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/arrow-back.svg");
    --icon-eye: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/grey/eye.svg");
    --icon-eye-crossed: url("https://lvbet-static.com/images/svg/icon_eye_slash_gray.svg");
    --icon-loader-primary: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/colored/sports/football.svg");
    --icon-loader-secondary: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/colored/sports/volleyball.svg");
    --icon-loader-tertiary: url("https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/colored/sports/basketball.svg");
}