:root {
    --merchant-basic: var(--basic-color);
    --merchant-body: var(--body);
    --merchabt-line: var(--primary-color);
    --input-merchant: var(--primary-color);
    --c-input-merchant: #b6b6b6;
}

.merchant {
    --radius-primary: 15px;

    display: grid;
    row-gap: 15px;
    padding: 15px 0;

    .sb-virtual-keyboard {
        padding: 15px 11px;
        color: var(--c-input-merchant);
        background: var(--input-merchant);
        font-weight: 600;
        font-size: 12px;
        border-radius: var(--radius-primary);
    }

    .btn-form {
        height: 47px;
        width: 100%;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: -0.05em;
        text-transform: uppercase;
    }

    .btn-submit {
        background-color: var(--basic-color);
        color: var(--black);
    }

    .btn-copy {
        background-color: transparent;
        color: var(--white);
        border: 2px solid var(--primary-color);
        border-radius: var(--radius-primary);
        transition: border-color 0.2s ease-out;

        &:active {
            border-color: var(--merchant-basic);
        }
    }

    .basic-form-label {
        margin-bottom: 10px;
        font-weight: 700;
    }

    .basic-headline {
        .icon-cp {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: var(--basic-color);
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__title {
            color: var(--merchant-basic);
            font-style: initial;
        }
    }
}

.merchant-cards {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 18px;

    &-entry {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px;
        background-color: #1c1c23;
        color: var(--white);
        font-weight: 800;
        line-height: 1;
        letter-spacing: -0.05em;
        border-radius: var(--radius-primary);
        outline: none;

        .icon-cp {
            width: 30px;
            height: 30px;
            fill: #b7b7c2;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &__content {
            display: grid;
            row-gap: 8px;
        }

        &__title {
            font-size: 24px;
            @include capitalize;

            @include breakpoint-min(768px) {
                font-size: 26px;
            }
        }

        &__subtitle {
            color: var(--basic-color);
            font-size: 13px;
            text-transform: uppercase;

            @include breakpoint-min(768px) {
                font-size: 14px;
            }
        }
    }
}

.merchant-scan {
    display: grid;
    row-gap: 30px;

    &-video {
        position: relative;
        height: 200px;
        margin-bottom: 20px;
        border: 2px solid var(--primary-color);
        border-radius: var(--radius-primary);

        &__entry {
            @include absolute-full-size;

            margin: 0 auto;
            border-radius: var(--radius-primary);
        }
    }
}

.merchant-coupons {
    display: grid;
    row-gap: 20px;
    border-radius: var(--radius-primary);

    .btn {
        height: 47px;
        width: 100%;
        background-color: var(--primary-green);
        font-size: 13px;
        font-weight: 700;
        letter-spacing: -0.05em;
        text-transform: uppercase;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        color: var(--white);
        font-size: 15px;
        font-weight: 800;
        letter-spacing: -0.05em;
        text-transform: uppercase;
    }

    &-empty {
        display: grid;
        row-gap: 12px;
        justify-content: center;
        margin: 10px 0;
        color: var(--white);
        font-size: 14px;
        font-weight: 900;
        letter-spacing: -0.05em;
        text-transform: uppercase;
        text-align: center;

        .icon-cp {
            width: 40px;
            height: 40px;
            margin: 0 auto;
            fill: var(--basic-color);
        }

        span {
            margin-left: 3px;
            color: var(--basic-color);
        }
    }

    &-list {
        display: grid;
        grid-template-columns: minmax(0, 1fr);

        &-entry {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            padding: 10px 0;
            color: var(--grey);
            letter-spacing: -0.05em;
            text-transform: uppercase;
            border-top: 1px dotted;

            &:last-child {
                border-bottom: 1px dotted;
            }

            .icon-cp {
                width: 12px;
                height: 12px;
                margin-left: 15px;
                fill: var(--white);
            }

            .has-won {
                color: var(--primary-green);
            }

            .has-lost {
                color: var(--primary-red);
            }

            &__data {
                font-size: 12px;
                font-weight: 700;
            }

            &__val {
                color: var(--white);
            }

            &__status {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    &-summary {
        display: grid;
        grid-gap: 5px;
        color: var(--f-grey-12);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.05em;
        text-transform: uppercase;

        &__data {
            display: flex;
            justify-content: space-between;
        }
    }
}

.merchant-data {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 10px;
    color: var(--f-grey-12);

    &__headline {
        color: var(--white);
        font-size: 16px;
        font-weight: 800;
        letter-spacing: -0.05em;
        text-transform: uppercase;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 12px;
    }

    &-entry {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 3px;
        font-weight: 700;

        &__label {
            color: var(--basic-color);
            @include capitalize;
        }
    }
}

.merchant-map {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 20px;
    color: var(--f-grey-12);
    letter-spacing: -0.05em;

    .is-success {
        color: var(--primary-green);
    }

    .is-error {
        color: var(--primary-red);
    }

    &-state {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;
        font-size: 15px;
        font-weight: 700;

        &__entry {
            @include capitalize;

            span {
                font-weight: 800;
                text-transform: uppercase;
            }
        }
    }

    &-action {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 20px;
    }
}

.merchant-report {
    display: grid;
    row-gap: 30px;

    &__details {
        display: grid;
        row-gap: 15px;
    }

    .merchant-data {
        padding-bottom: 15px;
        border-bottom: 1px dotted;
    }
}

.merchant-qr-login {
    display: grid;
    grid-gap: 15px;
    padding: 10px 0;
    color: var(--white);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.025em;
    text-transform: uppercase;
}

.merchant-error {
    color: var(--red);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.025em;
    @include capitalize;
}

.sb-modal-payout {
    .sb-modal__content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;
        margin-bottom: calc(0px + var(--safe-area-bottom));
    }

    .sb-modal__text {
        color: var(--f-grey-4);
        font-weight: 600;
    }

    .btn-payout {
        height: 47px;
        width: 100%;
        margin: 0 auto;
        background-color: var(--primary-green);
        color: var(--black);
        font-size: 16px;
        font-weight: 800;
        letter-spacing: -0.05em;
        text-transform: uppercase;
    }
}

.bets {
    margin: 0 -14px;
}

.bets-header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-bottom: 20px;
}

.bets-env {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    color: var(--white);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;

    &__entry {
        position: relative;
        padding: 12px;
        text-align: center;
        border-bottom: 2px solid var(--primary-color);

        &.is-active {
            color: var(--basic-color);
            border-color: var(--basic-color);
        }
    }
}

.bets-type-filter {
    overflow-y: scroll;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 14px;

    &::before,
    &::after {
        position: absolute;
        bottom: 0;
        width: 16px;
        height: 40px;
        content: "";
    }

    &::before {
        left: 0;
        background: linear-gradient(
            to right,
            var(--body) 55%,
            rgb(255 255 255 / 0%) 100%
        );
    }

    &::after {
        width: 20px;
        right: 0;
        background: linear-gradient(
            to left,
            var(--body) 55%,
            rgb(255 255 255 / 0%) 100%
        );
    }

    &__entry {
        padding: 8px 16px;
        margin-right: 8px;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        border: 2px solid var(--primary-color);
        border-radius: var(--radius-primary);
        border-radius: 15px;

        &.is-active {
            border-color: var(--basic-color);
        }
    }
}

.bets-table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    &-header {
        background-color: var(--primary-color);
        color: var(--white);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.05em;
        text-transform: capitalize;

        &-cell {
            padding: 5px 0;
        }
    }

    &-body {
        background-color: #191920;
    }

    &-row {
        border-bottom: 2px solid var(--body);
    }
}

.bets-table .table-cell {
    position: relative;
    padding: 12px 0;
    vertical-align: middle;
    color: #b7b7c2;

    &-content {
        font-size: 11px;
        font-weight: 700;
        letter-spacing: -0.05em;
        text-transform: uppercase;
        text-align: center;
    }

    &-desc {
        display: grid;
        row-gap: 3px;
    }

    &-status {
        color: var(--white);
    }

    .has-won {
        color: var(--primary-green);
    }

    .has-type {
        color: var(--white);
    }

    .has-lost {
        color: var(--red-2);
    }
}

.coupon {
    --radius-primary: 15px;

    display: block;
    padding: 10px;
    margin: 10px 0;
    background-color: var(--grey-7);
    border-radius: var(--radius-primary);

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 0;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.05em;
        text-transform: uppercase;

        .has-won {
            color: var(--primary-green);
        }

        .has-lost {
            color: var(--red-2);
        }

        &__name span {
            margin-left: 5px;
            color: var(--f-grey-2);
        }
    }

    &-body {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 10px;
        margin: 15px 0;
    }
}

.coupon-bet {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 10px;
    padding: 12px;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: var(--radius-primary);

    .indicator-live {
        margin-right: 5px;
        padding: 0 5px;
        background-color: var(--red-2);
        font-size: 9px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 5px;
        line-height: 15px;
    }

    &__row {
        display: flex;
        align-items: center;

        &.has-event {
            justify-content: space-between;
        }
    }

    &__col.has-status {
        display: flex;
        align-items: center;
    }
}

.coupon-bet-selection {
    display: grid;
    row-gap: 7px;
    font-size: 12px;

    &__entry {
        display: flex;
        color: var(--white);
        letter-spacing: -0.05em;

        &.has-game {
            flex-wrap: wrap;
            font-weight: 700;
        }

        &.has-selection {
            font-size: 11px;
            font-weight: 600;
        }
    }

    &__title {
        margin-right: 2px;
        color: var(--f-grey-2);
    }

    &__separator {
        padding: 0 5px;
        color: var(--f-grey-2);
    }
}

.coupon-bet-odds {
    position: relative;
    padding: 7px 8.5px;
    min-width: 45px;
    background-color: var(--body);
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-align: center;
    border-radius: var(--radius-primary);
}

.coupon-bet-status {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    border-radius: 50%;
    border: 2px solid #6f6f78;

    &.has-won {
        border-color: var(--primary-green);
        background: var(--primary-green)
            url("/img/new-version/icons/black/tick.svg") no-repeat center/8px;
    }

    &.has-lost {
        border-color: var(--red-2);
        background: var(--red-2) url("/img/new-version/icons/black/failure.svg")
            no-repeat center/8px;
    }
}

.coupon-summary {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 15px;
    padding: 12px;
    background-color: var(--body);
    border: 2px solid var(--primary-color);
    border-radius: var(--radius-primary);

    &__row {
        display: flex;
        justify-content: space-between;
        color: var(--white);
        font-weight: 700;

        &.has-details {
            color: var(--f-grey-18);
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    &__name {
        text-transform: capitalize;
    }

    &__value {
        color: var(--basic-color);
        text-transform: uppercase;
    }
}

.coupon-summary-details {
    display: flex;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.025em;

    &__entry {
        margin-right: 10px;
    }
}

.coupon-system {
    margin-bottom: 5px;

    &__bets {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 10px;
    }
}

.btn-reprint,
.btn-show-systems {
    height: 40px;
    width: 100%;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;
}

.btn-reprint {
    margin-bottom: 20px;
    color: var(--black);
    background-color: var(--basic-color);
    border-radius: var(--radius-primary);
}

.btn-show-systems {
    background: var(--primary-color);
    color: var(--white);
}

.pagination {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 8px;
    margin: 20px 0;

    .icon-cp {
        width: 13px;
        height: 13px;
        fill: var(--primary-color);
    }

    &-entry {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        background-color: var(--body-color);
        color: #595964;
        font-weight: 700;
        letter-spacing: -0.05em;
        border: 2px solid var(--primary-color);
        border-radius: var(--radius-primary);

        &.is-active {
            color: var(--white);
        }
        
        &.is-disabled {
            pointer-events: none;
        }
    }
}
